body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/AvenirLTStd-Book.eot");
  src: url("./assets/fonts/AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg"),
    url("./assets/fonts/AvenirLTStd-Book.ttf") format("truetype"),
    url("./assets/fonts/AvenirLTStd-Book.woff") format("woff"),
    url("./assets/fonts/AvenirLTStd-Book.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Bold";
  src: url("./assets/fonts/AvenirLTStd-Black.eot");
  src: url("./assets/fonts/AvenirLTStd-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg"),
    url("./assets/fonts/AvenirLTStd-Black.ttf") format("truetype"),
    url("./assets/fonts/AvenirLTStd-Black.woff") format("woff"),
    url("./assets/fonts/AvenirLTStd-Black.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

.font-avenir {
  font-family: "Avenir", Arial, Helvetica, sans-serif;
}

.font-avenir-bold {
  font-family: "Avenir-Bold", Arial, Helvetica, sans-serif;
}

.blur-bg {
  width: 100%;
  top: 0;
  left: 0;
  filter: blur(6px);
}

.unpaid-delete-modal-body {
  min-height: auto;
}

.unpaid-delete-header.modal-header {
  background-color: white;
}

.unpaid-delete-header .delete-modal-body {
  min-height: auto;
}

.popover {
  max-width: 100vw !important;
}