@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/buttons";

$enable-negative-margins: true;
$gray: #f3f0e8;
$gray-600: #4a4a4a;
$white: #ffffff;
$black: #000000;
$light-gray: #ced4da;
$input-placeholder-color: $light-gray;

$orange: #f7941e;
$green: #28a745;
$orange-100: lighten($orange, 10%);
$orange-200: lighten($orange, 20%);
$orange-300: lighten($orange, 30%);

$btn-link-color: $orange;
$link-color: $orange;
$link: $orange;
$link-active-color: $orange;
$link-hover-color: $orange;
$card-cap-bg: $orange;
$dropdown-link-active-bg: $orange;

$dark-black: #0a030c;
$blue: #4a90e2;
$dark-gray: #c4c4c5;
$theme-colors: (
  "primary": $orange,
  "secondary": $black,
  "success": $green,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);


// SOURCE => https://github.com/twbs/bootstrap/issues/23892#issuecomment-396831166
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix} {
      border: $border-width solid $border-color;
    }

    .border#{$infix}-top {
      border-top: $border-width solid $border-color;
    }

    .border#{$infix}-right {
      border-right: $border-width solid $border-color;
    }

    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color;
    }

    .border#{$infix}-left {
      border-left: $border-width solid $border-color;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }

    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }

    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }

    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color;
      border-right: $border-width solid $border-color;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color;
      border-bottom: $border-width solid $border-color;
    }
  }
}

// SASS Mixin and utility classes to change the size of ReactStrap/Bootstrap Switch/toggle
// SOURCE => https://codepen.io/nisharg/pen/VwLbYvv
@mixin switch($res: "sm") {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res =="md" {
    $index: 2rem;
    $mainVal: 1.5rem;
  }

  @else if $res =="lg" {
    $index: 3rem;
    $mainVal: 2rem;
  }

  @else if $res =="xl" {
    $index: 4rem;
    $mainVal: 2.5rem;
  }

  .custom-control-label {
    padding-left: #{$index};
    padding-bottom: #{$mainVal};
  }

  .custom-control-label::before {
    height: $mainVal;
    width: calc(#{$index} + 0.75rem);
    border-radius: $mainVal * 2;
  }

  .custom-control-label::after {
    width: calc(#{$mainVal} - 4px);
    height: calc(#{$mainVal} - 4px);
    border-radius: calc(#{$index} - (#{$mainVal} / 2));
  }

  .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(calc(#{$mainVal} - 0.25rem));
  }
}

.custom-switch.custom-switch-sm {
  @include switch();
}

.custom-switch.custom-switch-md {
  @include switch("md");
}

.custom-switch.custom-switch-lg {
  @include switch("lg");
}

.custom-switch.custom-switch-xl {
  @include switch("xl");
}

.cursor-pointer {
  cursor: pointer;
}

@import "~bootstrap/scss/bootstrap";