.sub-msg-ms {
  margin-left: 2.3rem;
}
.sub-msg-me {
  margin-right: 2.3rem;
}

.rm-box-shadow {
  box-shadow: none !important;
}

.border-bottom-right-0 {
  border: 0px;
  border-bottom-right-radius: 0px !important;
}

.border-top-right-0 {
  border-top-right-radius: 0px !important;
}

.border-top-left-0 {
  border-top-left-radius: 0px !important;
}

.send-button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
}

.email-input:focus {
  box-shadow: none !important;
}
