@import "../../utils/custom-variables.scss";

.header-container {
  overflow: visible;
  height: auto;
  width: 100%;
  position: sticky !important;
  top: 0;
  z-index: 10000;
}

.header-navbar {
  background-color: $white;
  .navbar-brand {
    img {
      width: 12rem;
      height: auto;
    }
  }

  .navbar-nav {
    .nav-item {
      color: $black;

      .nav-link {
        color: $black;
        font-size: 1rem;
        &:hover,
        &:active,
        &:focus,
        &.active {
          color: $orange;
        }

        &:not(.nav-btn) {
          margin-top: 0.6rem;
          margin-right: 1.5rem;
        }
      }

      .nav-btn {
        margin-top: 2px;
        .btn {
          font-size: 1.2rem;
          padding: 0.45rem 1rem;
          font-weight: bold;
        }
      }
    }
  }
}

.btn-tertiary {
  background-color: $white;
  border: 2px solid lighten($orange, 20%) !important;
  color: $black;

  &:hover,
  &:active {
    background-color: $white;
    color: $black;
    border: 2px solid lighten($orange, 20%);
  }
}

.navbar-underline {
  width: 100%;
  height: 5px;
  background-color: orange;
}

@include media-breakpoint-down(md) {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 1rem;

        &:not(.nav-btn) {
          margin-right: 0.6rem;
        }
      }

      .nav-btn {
        padding-right: 0;

        .btn {
          font-size: 1rem;
          padding: 0.35rem 0.8rem;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .header-navbar {
    padding: 0.5rem 1rem;

    .navbar-brand {
      img {
        width: 10rem;
      }
    }
  }
}

.schedule-btn {
  background-color: #33c003 !important;
  border: none !important;

  &:hover {
    background-color: #6bca4c !important;
  }

  &:focus {
    box-shadow: unset !important;
  }

  &:hover {
    border: unset !important;
  }
}

.navbar-logo-container {
  width: 170px;
}

.header-navbar-link {
  color: black;
  @include font-size(0.95rem);

  &:hover {
    color: $orange;
  }
}

.navbar-signup-button,
.navbar-login-button {
  margin-bottom: 10px;
  border: 0 !important;
  font-size: 0.95rem;
}

.navbar-login-button,
.navbar-login-button:visited,
.navbar-login-button:focus,
.navbar-login-button:hover,
.navbar-login-button:active {
  background-color: #e9ebec !important;
  color: black !important;
  margin-top: 3px;
}

.navbar-signup-button,
.navbar-signup-button:visited,
.navbar-signup-button:focus,
.navbar-signup-button:hover,
.navbar-signup-button:active {
  background-color: #f7941e !important;
  color: white !important;
  margin-top: 3px;
}

.home-button-padding {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

@media (max-width: 991px) {
  .button-margin {
    margin-bottom: 6px !important;
  }
}

