@import "../../utils/custom-variables.scss";

footer {
  background-color: $black;
  color: $white;

  a {
    font-size: 1.2rem;
    color: $white !important;
    text-decoration: none;

    &:hover {
      color: $orange !important;
    }
  }

  .foretheta-logo {
    width: 116px;
    height: 22px;
  }
}